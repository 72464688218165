import { Track, TrackStyle, TrackTheme } from "./types/track";

function createTheme(): TrackTheme {
    const sky = Math.random() > .5 ? "day" : "night";
    const roadHeight = 0.3 + Math.random() * 0.3;
    const lensFlareOpacity = 0.5 + Math.random() * 0.5;
    const particlesOpacity = Math.random();
    const isRoadDoubleSided = true;
    return {
        sky,
        roadHeight,
        lensFlareOpacity,
        particlesOpacity,
        isRoadDoubleSided
    }
}

// {
//     roadCenterColor: 0x2070b8,
//         roadEdgeColor: 0x80f0ff,
//             strips: [
//                 [0, 300],
//                 [350, 50],
//                 [950, 50],
//             ],
//                 glassColor: 0xccddee,
//                     bump: 0.1,
//                         shininess: 1000,
//     },

function createColor() {
    const light = Math.random();
    const hsl = Math.random();
}

function color(hue: number, light: number) {
    let color = 0x000000;
    if (hue < 60) {
        color = (0xc0 << 16) + (Math.floor(0xa0 * (hue / 60)) << 8)
    } else if (hue < 120) {
        color = (0xa0 << 8) + (Math.floor(0xc0 * ((120 - hue) / 60)) << 16);
    } else if (hue < 180) {
        color = (0xa0 << 8) + (Math.floor(0xc0 * ((hue - 120) / 60)) << 0)
    } else if (hue < 240) {
        color = (0xc0 << 0) + (Math.floor(0xa0 * ((240 - hue) / 60)) << 8);
    } else if (hue < 300) {
        color = (0xc0 << 0) + (Math.floor(0xc0 * ((hue - 240) / 60)) << 16)
    } else {
        color = (0xc0 << 16) + (Math.floor(0xc0 * ((360 - hue) / 60)) << 0);
    }
    const lightValue = 0xd0 * light;
    color += (lightValue << 16) + (lightValue << 8) + (lightValue << 0)
    return color
}

const roadWidth = 0.7 + Math.random() * 0.6
const width = Math.random();
const bump = Math.random();
const shininess = Math.random() * 1500
function createStyle(): TrackStyle {
    const hue = Math.floor(Math.random() * 360);
    return {
        roadCenterColor: color(hue, 0),
        roadEdgeColor: color(hue, 1),
        strips: [
            [0, 200 + width * 200],
            [200 + width * 200 + 40 + width * 60, 40 + width * 60],
            [1050, 40 + width * 60],
        ],
        glassColor: 0x000000,
        bump: bump,
        shininess: shininess,
    }
}

const track: Track = {
    gamePlay: {
        lapCount: 5,
        speed: 1,
    },
    theme: createTheme(),
    styles: {
        red: createStyle(),
        blue: createStyle(),
    },
    segments: [
        {
            style: "blue",
            width: roadWidth,
            vertices: [
                [0, 0, 0, 0, 0],
                [7, 0, 0, 0, 0],
                [9, 0, -2, -90, 0],
                [9, 0, -3, -90, 0],
                [9, 0.5, -5, -90, 0],
            ],
        },
        {
            style: "red",
            width: roadWidth,
            vertices: [
                [9, 0, -5.5, -90, 0],
                [9, 0, -6, -90, 0],
                [6, 0, -8, -180, 0],
                [5, 0, -8, -180, 0],
                [3.5, 0, -8.5, -135, 0],
                [2, 0, -9, -180, 0],
                [1, 0, -9, -180, 0],
                [0, 0, -8.5, -225, 0],
                [-1.5, 0, -8, -180, 0],
                [-3, 0, -8, 180, 0],
                [-8, 0, 0, 90, 0],
                [-8, 0.5, 2.5, 90, 0],
            ],
        },
        {
            style: "blue",
            width: roadWidth,
            vertices: [
                [-8, 0, 3, 90, 0],
                [-8, 0, 4, 90, 0],
                [-4, 0, 8, 0, 0],
                [-1, 0.75, 8, 0, 0],
            ],
        },
        {
            style: "red",
            width: roadWidth,
            vertices: [
                [0, 0, 8, 0, 0],
                [6, 0.5, 8, 0, 0],
                [8, 1.5, 4, -90, 0],
                [6, 2.25, 0, -180, 0],
                [4, 2.5, 0, -180, 0],
                [2, 3, 0, -180, 0],
            ],
        },
        {
            style: "red",
            width: roadWidth,
            vertices: [
                [1, 2.25, 0, -180, 0],
                [-2, 2.25, 0, -180, 0],
                [-4, 2.75, 0, -180, 0],
            ],
        },
        {
            style: "blue",
            width: roadWidth,
            vertices: [
                [-5, 2, 0, -180, 0],
                [-10, 2, 0, -180, 0],
                [-12, 2, -2, -90, 0],
                [-15, 1.5, -4, -180, 0],
                [-18, 1, -2, -270, 0],
                [-18, 0.5, 0, -270, 0],
                [-18, 0, 2, 90, 0],
                [-15, -0.5, 4, 0, 0],
                [-12, -1, 2, -90, 0],
                [-9, -1.5, 0, 0, 0],
                [-4.5, -1, 0, 0, 0],
                [0, 0, 0, 0, 0],
            ],
        },
    ],
    environment: [],
    startingPoint: [
        [0, 0, 0, 0, 0],
        [1, 1],
    ],
};

for (const sg in track.segments) {
    for (const p in track.segments[sg].vertices) {
        // track.segments[sg].vertices[p][4] += 20;

    }
}

export default track;
